
// Write your custom style. Feel free to split your code to several files
h1, h2, h3, h4 {
  color: $color-primary;
}

.header {
  padding-top: 200px;
  padding-bottom:130px;
  overflow:hidden;
  &:after {
    display: block;
    position: absolute;
    bottom: -900px;
    height: 1000px;
    transform: rotate(-1.8deg);
    background-color: #FFF;
    content: "";
    width: 100%;
  }
}

.navbar-brand img {
  max-height: 48px;
}

.navbar-dark .nav-navbar>.nav-item>.nav-link, .navbar-dark .nav-navbar>.nav-link, .navbar-stick-dark.stick .nav-navbar>.nav-item>.nav-link, .navbar-stick-dark.stick .nav-navbar>.nav-link {
  color: $color-primary;
}

.navbar {
  top: 30px;
  .container {
    background-color: #FFF;
    padding: 15px 25px;
    justify-content: flex-start;
    .button-container {
      display: flex;
      justify-content: flex-end;
      flex: 1;
      a.btn {
        border-radius: 2px;
      }
    }
  }
}

.nav-navbar .nav-link {
  text-transform: none;
  font-size: 1em;
  font-weight: 700;
  letter-spacing: normal;
}

.navbar-mobile {
  width: auto;
}

